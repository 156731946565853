import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-very-dark-blue text-white py-12">
      <div className="container mx-auto px-4 lg:px-20 xl:px-28 2xl:px-40 max-w-screen-xl flex flex-col md:flex-row justify-between items-center">

        {/* Social Media Links */}
        <div className="flex space-x-4 mb-6 md:mb-0">
          <a
            href="https://www.facebook.com/sokesolutionslimited/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="text-2xl hover:text-orange transition-colors duration-300"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/soke_computers_ltd/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
            className="text-2xl hover:text-orange transition-colors duration-300"
          >
            <FaInstagram />
          </a>
        </div>

        {/* Navigation Links */}
        <nav className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 mb-6 md:mb-0">
          <Link to="/about" className="hover:text-orange transition-colors duration-300">About Us</Link>
          <Link to="/returnpolicy" className="hover:text-orange transition-colors duration-300">Return Policy</Link>
          <Link to="/products" className="hover:text-orange transition-colors duration-300">Products</Link>
        </nav>

        {/* Contact and Copyright Information */}
        <div className="text-center md:text-left">
          <div className="flex items-center justify-center md:justify-start mb-4">
            <FaMapMarkerAlt className="mr-2 text-xl" />
            <span>Bazaar Plaza, Moi Avenue, M1 Unit 4/001</span>
          </div>
          <div>
            <span className="block mb-2">
              © Copyright 2025{" "}
              <Link
                to="/products"
                className="border-b border-transparent hover:border-orange hover:text-very-dark-blue transition-colors duration-300"
              >
                Soke Solutions
              </Link>{" "}
              All rights reserved.
            </span>
          </div>
        </div>
      </div>

      {/* Payment Image at the Bottom */}
      <div className="bg-dark-gray py-4">
        <div className="container mx-auto px-4">
          <img
            src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
            alt="Accepted Payment Methods"
            className="max-w-full h-auto mx-auto"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
