import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, quantityCount } from "../../redux/reducers/cartSlice";
import { FaWhatsapp } from "react-icons/fa";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product);
  const quantity = useSelector((state) => state.cart.quantity);
  const cartItems = useSelector((state) => state.cart.cartItems);
  document.title = `${product.name}`;

  useEffect(() => {
    dispatch(quantityCount(1));
    // eslint-disable-next-line
  }, [cartItems]);
  const handleWhatsAppClick = () => {
    const productLink = window.location.href; // Get the current page URL as the product link
    const message = `Product: ${product.name}\nPrice: Ksh.${product.price}\nQuantity: ${quantity}`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=254769758399&text=${encodeURIComponent(
      message
    )}%0A${encodeURIComponent(productLink)}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <>
      <h2 className="company uppercase text-orange font-bold text-sm sm:text-md tracking-wider pb-3 sm:pb-5">
        {product.category}
      </h2>
      <h3 className="product capitalize text-very-dark-blue font-bold text-3xl sm:text-4xl sm:leading-none pb-3">
        {product.name}
      </h3>

      <div className="amount font-bold flex items-center justify-between lg:flex-col lg:items-start mb-6">
        <div className="flex justify-between items-center text-[10px] md:text-xl gap-4">
          <div className="discount-price font-bold">
            Ksh.{product.discountPrice}
          </div>
          <div className="original-price text-grayish-blue line-through">
            Ksh{product.price}
          </div>
          <div className="discount flex items-center text-orange bg-pale-orange font-bold w-max px-2 sm:rounded mx-2 h-3 sm:h-6">
            {Math.floor(
              ((product.price - product.discountPrice) / product.price) * 100
            )}
            %
          </div>
        </div>
      </div>
      <div className="flex gap-2  mt-3 items-start justify-start">
        <div
          style={{
            backgroundColor: product.color,
          }}
          className=" ring-1 text-lg  h-4 w-4 md:h-8 md:w-8 rounded-full "
        ></div>
        <p className="text-md md:text-xl">{product.color}</p>
      </div>
      <div className="mx-auto px-14">
        <p className="text-lg text-center font-bold uppercase">
          General Specification
        </p>
        <p
          className="text-dark-grayish-blue pb-6 lg:py-7 lg:leading-6"
          dangerouslySetInnerHTML={{ __html: product.generalSpecification }}
        />
      </div>
      <div className="sm:flex lg:mt-8 w-full">
        <div className="quantity-container w-full bg-light-grayish-blue rounded-lg h-14 mb-4 flex items-center justify-between px-6 lg:px-3 font-bold sm:mr-3 lg:mr-5 lg:w-1/3">
          <button
            onClick={() => dispatch(quantityCount("decrease"))}
            className="text-orange text-2xl leading-none font-bold mb-1 lg:mb-2 lg:text-3xl hover:opacity-60"
          >
            -
          </button>
          <input
            min={0}
            max={100}
            onChange={(e) => dispatch(quantityCount(e.target.value))}
            className="quantity focus:outline-none text-dark-blue bg-light-grayish-blue font-bold flex text-center w-full"
            type="number"
            name="quantity"
            value={quantity}
            aria-label="quantity of products"
          />
          <button
            onClick={() => dispatch(quantityCount("increase"))}
            className="text-orange text-2xl leading-none font-bold mb-1 lg:mb-2 lg:text-3xl hover:opacity-60"
          >
            +
          </button>
        </div>
        <div className="flex  gap-5">
          <button
            onClick={() => dispatch(addToCart({ product, quantity }))}
            className="cart text-nowrap p-1 w-full h-14 bg-orange rounded-lg lg:rounded-xl mb-2 shadow-orange-shadow shadow-2xl text-white flex items-center justify-center lg:w-3/5 hover:opacity-60"
          >
            <i className="cursor-pointer text-white text-xl leading-0 pr-3">
              <ion-icon name="cart-outline"></ion-icon>
            </i>
            Add to cart
          </button>
          <button
            onClick={handleWhatsAppClick}
            className="whatsapp text-[12px] text-nowrap w-full h-14 bg-orange rounded-lg lg:rounded-xl mb-2 shadow-green-shadow shadow-2xl text-white flex p-1 items-center justify-center lg:w-3/5 hover:opacity-60"
          >
            <FaWhatsapp className="text-white" />
            Buy On WhatsApp
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
